<template>
  <section>
    <div style="margin-top: 2rem;text-align: center;color: #666666;font-size: 0.24rem">
      <span>服务器连接异常，请重新刷新</span>
    </div>
    <div style="margin-top: 0.3rem;text-align: center;font-size: 0.20rem;color: #999">
      <span>错误代码： {{ error_code }}</span>
    </div>
    <div style="margin-top: 0.5rem;text-align: center">
      <van-button type="primary" @click="to_other_page">点击刷新</van-button>
    </div>
  </section>
</template>

<script>
export default {
  props: [],
  name: 'error_page',
  data () {
    return {
      error_code: 11100,
      from_page_path: ''
    }
  },
  methods: {
    to_other_page () {
      this.$router.push({
        path: this.from_page_path
      })
    }
  },
  mounted () {
    // 原始地址
    console.log(this.$route.params)
    if (this.$route.params) {
      if (this.$route.params.page_path) {
        window.sessionStorage.setItem('from_page_path', this.$route.params.page_path)
      }
      this.from_page_path = window.sessionStorage.getItem('from_page_path')
      // 错误代码
      if (this.$route.params.error_code) {
        window.sessionStorage.setItem('error_code', this.$route.params.error_code)
      }
      this.error_code = window.sessionStorage.getItem('error_code')
    }
  }
}
</script>
